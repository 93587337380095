.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.react-datepicker__input-container input {
  border: none;
  height: 50px;
  background: transparent;
}

div.react-datepicker-popper {
  z-index: 2147483647;
}
